<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row align-v="end">
            <b-col cols="10">
              <div class="form-group">
                <validation-provider
                  name="anestesia"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <multiselect
                    v-if="!fields.custom"
                    v-model="fields.anestesia"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :showNoResults="true"
                    placeholder="Pesquisar anestesia"
                    class="search-mode with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar anestesia "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                  </multiselect>

                  <div class="custom-input" v-else>
                    <b-button variant="link" @click="setCustom(false)">
                      Selecionar um item da lista
                    </b-button>
                    <input
                      autocomplete="off"
                      v-model="fields.anestesia"
                      :readonly="!canEdit"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    />
                  </div>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="h-38 button"
                :disabled="invalid"
                @click="addItem"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th>Tipo de anestesia utilizada</th>
                  <th width="40%">Anestesista</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td>{{ value.anestesia }}</td>
                  <td>
                    <div v-if="value.editing !== true">
                      <b-button
                        variant="link"
                        v-if="value.anestesista === null"
                        class="p-0"
                        @click="editItem(index)"
                      >
                        Adicionar anestesista
                      </b-button>
                      {{ value.anestesista }}
                    </div>

                    <div class="edit-area" v-else>
                      <b-input
                        v-model="fields.anestesista"
                        class="form-control"
                        @blur="clearEditable"
                      />
                      <b-button
                        variant="primary"
                        class="mt-8"
                        @click="saveItem(index)"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          :disabled="!canEdit"
                          variant="link"
                          @click="editItem(index)"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    MoreVertical
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.anestesia,
    })
  },
  data() {
    return {
      options: [],
      fields: this.getDefaultFields(),
    }
  },
  async mounted() {
    try {
        const response = await this.api.getAnestesias()
        this.options = response.data.map(data => {
          return data.value
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/anestesia', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        custom: null,
        anestesia: null,
        anestesista: null
      }
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.anestesia = value ? searchTerm : null
    },
    addItem() {
      if (this.editingIndex === null && this.form.value.some(v => v.anestesia.toLowerCase() === this.fields.anestesia.toLowerCase())) {
        this.$toast.warning('Anestesia já adicionada')
        return
      }

      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(index) {
      const value = [...this.form.value]
      this.fields = {
        ...this.getDefaultFields(),
        anestesista: value[index].anestesista
      }
      value[index].editing = true
      this.handleProps({ key: 'value', value })
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    saveItem(index) {
      const value = [...this.form.value]
      value[index].anestesista = this.fields.anestesista
      delete value[index].editing
      this.fields = this.getDefaultFields()
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    clearEditable() {
      // setTimeout(() => {
      //   const value = [...this.form.value]
      //   value.map(v => v.editing && delete v.editing)
      //   this.handleProps({ key: 'value', value })
      //   this.fields = this.getDefaultFields()
      // }, 300)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 10px;
}
.edit-area {
  display: flex;
  gap: 10px;
  align-items: end;

  button {
    height: 38px;
  }
}
</style>
